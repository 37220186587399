import { createPinia, defineStore } from 'pinia'
import actions from './actions'
import getters from './getters'

const piniaInstance = createPinia()

const store = defineStore('proposal', {
  actions: actions,
  getters: getters,
  state: () => ({
    showModal: false,
    user: undefined,
    token: undefined,
    customer: {}
  })
})(piniaInstance)

export { store, piniaInstance }
