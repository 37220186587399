<template>
  <div>
    <header class="position-relative">
      <div class="intro-box position-absolute w-100 h-100 d-flex justify-content-center pt-5">
        <div
          class="w-50 w-sm-75 text-center d-flex align-items-center justify-content-center flex-column"
        >
          <h3 class="sub-title mb-4">Seu planejamento financeiro</h3>
          <div class="title text-center mb-4">
            <h1 class="main-title">{{ customer.customer_name }}</h1>
          </div>
          <p class="description mb-5">
            Vamos relembrar alguns pontos de extrema importância tratados na nossa reunião, sobre
            <b>como podemos otimizar as suas finanças</b> para que você atinja
            <b>de forma mais rápida e econômica os seus objetivos</b>.
          </p>
          <a class="btn-head mb-5" href="#profile">Vamos lá!</a>
          <div class="logo-box" data-aos="zoom-in" data-aos-duration="3000">
            <img src="@/assets/images/logo.svg" alt="futuro" />
          </div>
        </div>
      </div>
      <div class="container-video position-relative h-100">
        <div class="background-video h-100">
          <video class="h-100" autoplay loop muted>
            <source src="@/assets/video/background-webm.webm" type="video/webm" />
            <source src="@/assets/video/background-mp4.mp4" type="video/mp4" />
          </video>
        </div>
        <div class="filter position-absolute w-100 h-100"></div>
      </div>
    </header>

    <section
      class="about-you bg-white d-flex align-items-center justify-content-center flex-column position-relative pb-5"
    >
      <div class="background-top"></div>
      <div
        class="about-you__container w-100 d-flex align-items-center justify-content-start flex-column position-relative"
      >
        <div id="profile" class="about-you__container__profile">
          <img v-if="customer.avatar_url" :src="customer.avatar_url" alt="user" loading="lazy" />
          <img v-else src="@/assets/images/avatar.svg" alt="user" style="object-fit: contain" />
        </div>
        <div
          class="about-you__container__description mb-5"
          data-aos="fade-down"
          data-aos-duration="1000"
        >
          <p class="text-primary mb-3">
            Primeiramente, <b>eu gostaria de agradecer pelo nosso primeiro encontro</b>, espero que
            conversar sobre a sua vida financeira e seus sonhos tenha sido produtivo para você
            também!
          </p>
          <p>
            Antes de falarmos sobre seus planos e objetivos, é fundamental que você conheça a
            <b class="text-secondary">FUTURO</b>.
          </p>
        </div>
        <div class="about-you__container__until-here">
          <div
            class="about-you__container__until-here__box"
            data-aos="zoom-in-up"
            data-aos-duration="1000"
          >
            <p class="text-primary">
              Já são <b>mais de 12 anos de experiência</b> no mercado dos sócios fundadores.
            </p>
          </div>

          <div
            class="about-you__container__until-here__box"
            data-aos="zoom-in-up"
            data-aos-duration="1000"
          >
            <p class="text-primary">
              <b>Mais de 20 mil famílias</b> que já validam e utilizam nosso método
            </p>
          </div>
          <div
            class="about-you__container__until-here__box"
            data-aos="zoom-in-up"
            data-aos-duration="1000"
          >
            <p class="text-primary">
              <b>Seguimos rigorosamente os padrões da Planejar</b>, representante exclusiva da
              Certificação de Planejamento Financeiro Internacional.
            </p>
          </div>

          <div
            class="about-you__container__until-here__box"
            data-aos="zoom-in-up"
            data-aos-duration="1000"
          >
            <p class="text-primary">
              <b>Recebemos mais de 400 horas de curso</b> teórico e prático para nós, planejadores.
            </p>
          </div>
          <div
            class="about-you__container__until-here__box"
            data-aos="zoom-in-up"
            data-aos-duration="1000"
          >
            <p class="text-primary">
              <b>Buscamos sempre as melhores parcerias do mercado</b> e, já são  mais de 150 para
              garantir as melhores soluções para os  nossos clientes.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { store } from '@/store'
import * as types from '@/store/types'
import { storeToRefs } from 'pinia'

const { [types.CUSTOMER]: customer } = storeToRefs(store)
</script>

<style lang="scss" scoped>
header {
  height: 112dvh;

  @include media-h-sm-down {
    height: 200dvh;
  }

  .intro-box {
    z-index: 1;

    align-items: center;

    @include media-h-md-down {
      align-items: start;
    }

    .title {
      width: fit-content;
      border-bottom: 6px solid transparent;
      border-image: linear-gradient(0.25turn, #ac4800, $secondary, #ac4800);
      border-image-slice: 1;
    }

    .btn-head {
      border: 1px solid $secondary;
      border-image-slice: 1;
      border-radius: 15px;
      background-color: transparent;
      font-size: 24px;
      padding: 0.5rem 5rem;
      color: $white;
      transition: background-color 0.5s ease;

      &:hover {
        background-color: $secondary;
        text-decoration: none;
      }

      @include media-sm-down {
        padding: 0.5rem 2rem;
      }
    }
  }
  .background-video {
    width: 100%;
    position: absolute;
    z-index: -2;

    video {
      width: 100%;
      object-fit: cover;
    }

    .filter {
      z-index: -1;
    }
  }
}

.about-you {
  width: 100%;
  border-radius: 20px;

  &__container {
    width: 100%;
    height: 100%;
    padding: 125px 16.666% 0 16.666%;

    @include media-sm-down {
      padding: 125px 8.333% 0 8.333% !important;
    }

    &__profile {
      padding: 5px;
      position: absolute;
      top: -65px;
      background: $white;
      border-radius: 50%;

      img {
        width: 163px;
        height: 163px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    &__description {
      p {
        font-size: 20px;
      }
    }

    &__until-here {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 1.5rem;

      @include media-md-down {
        grid-template-columns: 1fr;
      }

      &__box {
        p {
          font-size: 16px;
          @include media-md-down {
            padding-bottom: 1rem;
            text-align: center;
            border-bottom: 3px solid #ac4800;
          }
        }
      }
    }
  }
}
</style>
