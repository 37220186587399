<template>
  <div>
    <section
      class="about-you d-flex align-items-center justify-content-center flex-column position-relative bg-footer"
    >
      <div
        class="about-you__container text-white w-100 d-flex align-items-center justify-content-start flex-column position-relative mb-3"
      >
        <div class="about-you__container__description mb-4" data-aos="zoom-in">
          <h1 class="text-center font-rubik-bold font-weight-bold mb-5">SEU FUTURO COMEÇA HOJE!</h1>
        </div>
      </div>

      <div class="about-you__container__box-footer w-100 mb-4 d-flex justify-content-between">
        <div>
          <div class="about-you__container__box-footer__social-media">
            <p class="text-white font-rubik mb-3 w-75">
              Acompanhe a Futuro em nossas redes sociais
            </p>
            <div class="about-you__container__box-footer__social-media__links mb-4">
              <a
                href="https://www.youtube.com/@futuroconsultoriafinanceir8910"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="@/assets/images/youtube.png" alt="youtube" />
              </a>
              <a
                href="https://www.instagram.com/futuroconsultoriafinanceira/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="@/assets/images/instagram.png" alt="instagram" />
              </a>
              <a
                href="https://www.linkedin.com/company/futuroconsultoriafinanceira/mycompany/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="@/assets/images/linkedin.png" alt="linkedin" />
              </a>
            </div>
            <div class="about-you__container__box-footer__social-media__whatsapp mb-5">
              <a
                href="http://wa.me/5511982039293"
                target="_blank"
                rel="noopener noreferrer"
                class="btn-head d-flex align-items-center"
              >
                <img class="mr-2" src="@/assets/images/whatsapp.png" alt="whatsapp" />
                <span class="text-start">Fale diretamente com nossa equipe</span>
              </a>
            </div>
          </div>
        </div>
        <div>
          <div class="about-you__container__box-footer__contacts text-white">
            <p class="mb-2">Qualquer dúvida, fico à disposição:</p>
            <h1 class="font-rubik-bold font-weight-bold">{{ customer.consultant_name }}</h1>
            <p>Parceiro Futuro</p>
            <p v-if="customer.consultant_cell_phone">
              Telefone:
              <a
                :href="`https://wa.me/${customer.consultant_cell_phone}`"
                target="_blank"
                rel="noopener noreferrer"
                class="text-secondary"
                >{{ customer.consultant_cell_phone }}</a
              >
            </p>
          </div>
        </div>
      </div>

      <div
        class="about-you__container__footer-logo d-flex align-items-center justify-content-center flex-column my-5 my-md-3"
      >
        <img src="@/assets/images/logo.svg" alt="logo" />
        <span class="text-white text-center"
          >® Futuro Consultoria Financeira - Todos os direitos reservados</span
        >
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { store } from '@/store'
import * as types from '@/store/types'
import { storeToRefs } from 'pinia'

const { [types.CUSTOMER]: customer } = storeToRefs(store)
</script>

<style lang="scss" scoped>
.about-you {
  width: 100%;
  padding: 125px 16.666% 0 16.666%;
  z-index: 3;
  overflow-x: hidden;

  @include media-sm-down {
    padding: 125px 8.333% 0 8.333% !important;
  }

  &__container {
    width: 100%;
    height: 100%;

    &__description {
      p {
        font-size: 36px;
        @include media-sm-down {
          font-size: 24px;
        }
      }

      h1 {
        font-size: 64px;
        @include media-sm-down {
          font-size: 36px;
        }
      }
    }

    &__box-footer {
      @include media-md-down {
        flex-direction: column-reverse;
      }
      &__social-media {
        @include media-md-down {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }
        p {
          font-size: 24px;
          @include media-md-down {
            text-align: center;
          }
        }

        &__links {
          width: fit-content;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          gap: 1.5rem;

          img {
            transition: 0.5s ease;
            &:hover {
              scale: 1.1;
            }
          }
        }

        &__whatsapp {
          margin-right: 1rem;
          @include media-md-down {
            margin-right: 0;
          }

          a {
            text-decoration: none;
          }
        }
      }

      &__contacts {
        @include media-md-down {
          margin-bottom: 3rem;
        }
        p {
          font-size: 24px;
        }

        h1 {
          font-size: 36px;
        }

        a {
          &:hover {
            color: $secondary !important;
          }
        }
      }
    }
  }
}

.btn-head {
  border: 1px solid $secondary;
  border-image-slice: 1;
  border-radius: 15px;
  background-color: transparent;
  font-size: 24px;
  padding: 0.5rem;
  color: $white;
  transition: background-color 0.5s ease;

  &:hover {
    background-color: $secondary;
  }
}
</style>
