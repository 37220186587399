export const depositions = {
  customers: [
    {
      name: 'Brenda Carmona',
      occupation: 'Médica Veterinária',
      deposition:
        'O valor pago por uma consultoria é muito baixo perto do que ela vai proporcionar o resto da vida. O mais difícil em conquistar o que se quer é a disciplina, e a consultoria te ajuda a focar no que é importante e melhor, te ensina onde aplicar seu dinheiro da forma mais saudável, segura e rentável. Trabalhar com a Futuro é fácil, tem-se o combo educação, paciência e conhecimento e uma paz de saber que a prosperidade mora ao lado.'
    },
    {
      name: 'Mariana de Arco e Flexa',
      occupation: 'Advogada e Mestranda',
      deposition:
        'Fernanda, muito obrigada por toda ajuda e por ter me esclarecido várias questões relevantíssimas sobre planejamento financeiro! Sua explicação em todas as reuniões foi muito didática e você me fez abrir os olhos para várias situações cotidianas que poderiam ser melhor geridas em termos de finanças. Eu te disse que queria que alguém me passasse tudo "mastigado" para entender e você, de fato, conseguiu me explicar muito bem vários pontos sobre os quais eu tinha dúvida, por exemplo: milhas, investimentos mais conservadores e/ou despojados, metas a curto, médio e longo prazo e onde colocar melhor meu dinheiro, procurando, sobretudo, atingir um objetivo. Sou muito grata pela parceria e agradeço pelo fato de você ter desempenhado seu trabalho de modo individualizado em relação às minhas necessidades e possibilidades! Valeu!!!'
    },
    {
      name: 'Felipe Banderó',
      occupation: 'Atleta de voleibol profissional',
      deposition:
        'Olá, meu nome é Felipe Banderó, sou atleta de voleibol profissional. Eu encontrei na consultoria financeira um modo confortável de viver, economizando, pensando no futuro, ajudando a minha família a viajar e investir dinheiro no nosso futuro. A Futuro tem me ajudado muito, tem me acompanhado nessa minha carreira, porque a carreira de atleta é uma carreira muito curta e vai ser muito importante no meu futuro. Eu agradeço demais, muito obrigado.'
    },
    {
      name: 'Marcelo',
      occupation: 'Médico',
      deposition:
        'Meu nome é Marcelo, tenho 33 anos, sou médico, formado desde 2012. E eu conheci a Futuro Consultoria através de um amigo que me indicou. Porque em oito anos de formado eu nunca consegui poupar nenhum tipo de dinheiro, eu nunca consegui economizar. Sempre que eu ganhava, eu gastava. Eu acho que isso é o perfil de quase todos os brasileiros, ou a maioria. E através da Futuro Consultoria eu conheci o André, que é meu consultor financeiro. No primeiro dia de consultoria ele me mostrou um iceberg e me colocou no patamar onde eu estava de devedor. E eu achava que não. Ele me mostrou todos os degraus, inclusive onde eu queria chegar, onde eu queria alcançar, que seria investidor e ter uma independência financeira, que era o que eu quero alcançar. E ele falou que isso era possível e hoje eu sou um investidor. Eu saí de devedor em menos de um ano e eu sou muito grato a isso. Confiei, embora eu achei que fosse só uma propaganda no começo e estou colhendo bons frutos.'
    }
  ]
}
