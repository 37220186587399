import axios from 'axios'
import * as types from './types'
import { getCredentials, setCredentials, deleteCredentials } from '@/helpers/credentials'
import { CREDENTIALS_STORAGE_KEY } from '@/constants/auth'
import router from '@/router'
import { store } from '@/store'

export default {
  [types.SHOW_PROPOSAL_MODAL]() {
    store.showModal = !store.showModal
  },

  [types.SET_TOKEN](payload) {
    const { token, user } = payload
    store.user = user
    if (token) {
      store.token = token
      axios.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
      setCredentials(CREDENTIALS_STORAGE_KEY, { token, user })
    } else {
      const cookie = getCredentials(CREDENTIALS_STORAGE_KEY)
      setCredentials(CREDENTIALS_STORAGE_KEY, { ...cookie, user })
    }
  },

  [types.GET_USER_DATA_CREDENTIALS](payload) {
    return axios.post('/api/customers/login/by_token', payload).then((response) => {
      store[types.SET_TOKEN](response.data)
      router.push({ path: `/${response.data.user?.token}` })
    })
  },

  [types.GET_CUSTOMER](payload) {
    return axios
      .get(`/api/commercial_proposals/${payload}`)
      .then((response) => {
        store.customer = response?.data
      })
      .catch((error) => {
        router.push({ name: 'unauthorized' })
      })
  },

  [types.UPDATE_PAYMENT](customerToken, payload) {
    return axios
      .put(`/api/commercial_proposals/${customerToken}`, payload)
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  [types.GENERATE_CONTRACT]() {
    const customerToken = store.user?.token

    return axios
      .post('/api/contracts/generate', { token: customerToken })
      .then((resp) => {
        return Promise.resolve(resp)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
}
