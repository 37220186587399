import axios from 'axios'
import router from '@/router'
import * as types from '@/store/types'
import { CREDENTIALS_STORAGE_KEY } from '@/constants/auth'
import { getCredentials } from '@/helpers/credentials'
import { store } from '@/store'

export const initApi = () => {
  const credentials = getCredentials(CREDENTIALS_STORAGE_KEY) || undefined
  if (credentials) {
    store[types.SET_TOKEN](credentials)
  }

  axios.defaults.baseURL = process.env.VUE_APP_API_URL
  axios.defaults.withCredentials = false
  axios.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      if (
        401 === error?.response.status ||
        403 === error?.response?.status ||
        419 === error?.response?.status
      ) {
        router.push({ name: 'unauthorized' })
      }
      return Promise.reject(error)
    }
  )
}
