<template>
  <div class="token-auth w-100">
    <div class="container-fluid">
      <div class="d-flex flex-column h-100 align-items-center justify-content-center">
        <div class="box text-center">
          <h1 class="text-white font-weight-bold mb-4">Acesso não autorizado!</h1>
          <span>Você não possui permissão para acessar o sistema.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.token-auth,
.container-fluid {
  background-color: $primary;
  height: 100vh;
}

.container-fluid {
  img {
    width: 200px;
  }

  span {
    color: $white;
    font-size: 16px;
  }
}

.box {
  padding: 2rem;
  background-color: $blueDark;
  border-radius: 20px;
}
</style>
