<template>
  <div>
    <section
      class="about-you bg-white d-flex align-items-center justify-content-center flex-column position-relative"
    >
      <div
        class="about-you__container w-100 d-flex align-items-center justify-content-start flex-column position-relative"
      >
        <div class="about-you__container__description mb-5 text-primary">
          <p>
            Agora, vamos relembrar como será a sua <b>EXP</b>eriência com o nosso método

            <b class="text-secondary">Jornada EXP</b>:
          </p>
        </div>

        <div class="about-you__container__timeline d-flex">
          <div class="about-you__container__timeline__line mr-5">
            <div
              class="about-you__container__timeline__line__arrow-down d-flex align-items-center justify-content-center flex-column"
            >
              <img src="@/assets/images/check.svg" alt="check" />
              <div class="about-you__container__timeline__line__arrow-down__first-line"></div>

              <p class="text-center" data-aos="zoom-in" data-aos-duration="1500">Você está aqui</p>
            </div>
            <div
              class="about-you__container__timeline__line__arrow-down d-flex align-items-center justify-content-center flex-column"
            >
              <div class="about-you__container__timeline__line__arrow-down__second-line"></div>
              <img src="@/assets/images/no-check.svg" alt="no-check" />
            </div>
          </div>
          <div>
            <div
              class="about-you__container__timeline__box-info position-relative"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <img src="@/assets/images/e.svg" alt="entrevista" />
              <p>
                <b>“E” de Entrevista:</b> Esse foi nosso primeiro contato, onde falamos do
                principal, seus objetivos. Nessa primeira reunião eu pude diagnosticar que consigo
                te ajudar e muito!
              </p>
            </div>

            <div
              class="about-you__container__timeline__box-info position-relative pb-0"
              data-aos="fade-left"
              data-aos-duration="2000"
            >
              <img src="@/assets/images/x.svg" alt="momento x" />
              <p>
                <b>“X” de Momento X</b>: Nessa reunião, irei apresentar em <b>8 passos</b> como
                vamos alcançar a sua liberdade financeira. Além disso, tomaremos as decisões para
                chegar nos seus objetivos da melhor forma possível.
              </p>
              <div
                class="about-you__container__timeline__box-info__steps d-flex justify-content-around align-items-center w-100"
                data-aos="fade-right"
              >
                <div class="about-you__container__timeline__box-info__steps__goals">
                  <div class="d-flex justify-content-start align-items-center mb-2 mb-sm-3">
                    <img
                      class="position-static mr-sm-2 mr-1"
                      src="@/assets/images/atigindo.svg"
                      alt="atingido"
                    />
                    <span>Atingido</span>
                  </div>
                  <div class="d-flex justify-content-start align-items-center">
                    <img
                      class="position-static mr-sm-2 mr-1"
                      src="@/assets/images/nao-atigindo.svg"
                      alt="não atingido"
                    />
                    <span>Não atingido</span>
                  </div>
                </div>
                <div
                  class="about-you__container__timeline__box-info__steps__line-steps position-relative"
                  data-aos="fade-left"
                  data-aos-delay="400"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img class="position-static" src="@/assets/images/steps-ofi.png" alt="linha" />
                </div>
              </div>
            </div>

            <div
              class="about-you__container__timeline__box-info mb-0 position-relative"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <img src="@/assets/images/p.svg" alt="proposta" />
              <p>
                <b>“P” de Parceria</b>: Sabemos que uma vida financeira pode sofrer muitas mudanças,
                seja pelo impacto do mercado ou por objetivos pessoais. E para garantir que você
                tome as melhores decisões, vamos lhe dar todo o suporte e acompanhamento
                necessários.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.about-you {
  width: 100%;
  border-radius: 20px;
  overflow-x: hidden;

  &__container {
    width: 100%;
    height: 100%;
    padding: 125px 16.666%;

    @include media-sm-down {
      padding: 125px 8.333%;
    }

    &__description {
      p {
        font-size: 20px;
      }
    }

    &__timeline {
      &__line {
        margin-top: 2.5rem;
        &__arrow-down {
          span {
            font-size: 20px;
            line-height: 0;
            transform: rotate(90deg);
          }
          p {
            font-size: 16px;
            font-family: 'Font2';
          }

          &__first-line,
          &__second-line {
            width: 1px;
            background-color: $black;
          }

          &__first-line {
            height: 140px;
            @include media-sm-down {
              height: 191px;
            }
          }

          &__second-line {
            height: 380px;
            @include media-sm-up {
              height: 687px;
            }
            @include media-md-up {
              height: 574px;
            }
            @include media-lg-up {
              height: 427px;
            }
            @include media-xl-up {
              height: 423px;
            }
            @include media-2xl-up {
              height: 400px;
            }
          }
        }
      }

      &__box-info {
        max-width: 960px;
        padding: 2rem 0rem;
        margin: 2.5rem 0 4.5rem 0;
        border: 1px solid $secondary;
        background-color: #fefcfe;
        border-radius: 20px;

        img {
          position: absolute;
          top: -50px;
          left: -35px;

          @include media-sm-down {
            width: 69px;
            height: 69px;
            top: -25px;
            left: -35px;
          }
        }

        p {
          font-size: 16px;
          padding: 0rem 2rem 0rem 6rem;
          @include media-sm-down {
            padding: 0rem 1.5rem 0rem 1.5rem;
            font-size: 14px;
          }
        }

        &__steps {
          padding: 2rem 2rem 2rem 6rem;
          @include media-lg-down {
            flex-direction: column-reverse;
            padding: 2rem 1.5rem 2rem 1.5rem;
          }
          &__goals {
            @include media-sm-down {
              margin-top: 1rem;
            }

            img {
              height: 44px;

              @include media-sm-down {
                height: 25px;
              }
            }

            span {
              font-size: 16px;
              font-family: 'Font1';
            }
          }

          &__line-steps {
            img {
              height: 201px;

              @include media-sm-down {
                width: 178px;
                height: auto;
              }
            }
            &__numbers {
              position: absolute;
              img {
                height: 49.22px;

                @include media-sm-down {
                  height: 22px;
                }
              }

              &:nth-child(2) {
                top: 0;
                left: 30px;
              }
              &:nth-child(3) {
                top: 0;
                left: 130px;
              }
              &:nth-child(4) {
                top: 57px;
                left: 190px;
              }
              &:nth-child(5) {
                top: 125px;
                right: 41px;
              }
              &:nth-child(6) {
                top: 74px;
                right: -13px;
              }
              &:nth-child(7) {
                top: 26px;
                right: 41px;
              }
              &:nth-child(8) {
                bottom: -6px;
                left: 130px;
              }
              &:nth-child(9) {
                bottom: -6px;
                left: 30px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
