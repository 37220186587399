<template>
  <div class="modal-proposal d-flex align-items-center justify-content-center">
    <section class="d-flex align-items-center justify-content-center flex-column">
      <div
        class="box-top bg-danger position-relative d-flex align-items-center justify-content-center"
      >
        <img src="@/assets/images/exclamation.svg" alt="erro" />
      </div>
      <div class="box-info bg-white p-4">
        <div class="box-info__text text-center">
          <h3 class="mb-3">Ooops!</h3>
          <p class="mb-3">Não foi possível realizar sua autenticação.</p>
          <p class="mb-3">Entre em contato com o seu planejador financeiro.</p>
        </div>
      </div>
      <div class="modal-background"></div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.modal-background,
.modal-proposal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
}
.modal-background {
  background-color: rgba(0, 0, 0, 0.2);
}
.modal-proposal {
  z-index: 4;

  section {
    width: 332px;
    // height: 382px;
    border-radius: 20px;

    .box-top {
      width: 100%;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      padding: 1.5rem;
      z-index: 1;
      &__text {
        h1 {
          font-size: 24px;
        }

        p {
          font-size: 14px;
        }
      }
    }

    .box-info {
      width: 100%;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      z-index: 1;

      &__text {
        p {
          font-size: 14px;
        }
      }

      &__button {
        width: 100%;

        a {
          font-size: 14px;
          border-radius: 10px;
        }
      }
    }
  }
}
</style>
