<template>
  <div class="token-auth w-100">
    <ErrorModal v-if="modal" />
    <div v-else class="container-fluid">
      <div class="d-flex flex-column h-100 align-items-center justify-content-center">
        <img
          src="@/assets/images/logo-white.svg"
          height="150"
          alt="Futuro | Consultoria Financeira"
        />
        <span class="mt-4">Estamos autenticando você</span>
        <span class="mb-4">Aguarde um momento...</span>
        <pixel-spinner :animation-duration="1500" :pixel-size="70" color="#fff" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue'
import { PixelSpinner, LoopingRhombusesSpinner } from 'epic-spinners'
import ErrorModal from '@/components/ErrorModal.vue'
import { store } from '@/store'
import * as types from '@/store/types'
import { useRouter, useRoute } from 'vue-router'

const route = useRoute()

const modal = ref(null)

const { [types.GET_USER_DATA_CREDENTIALS]: loginByToken } = store

function getToken(token) {
  loginByToken({
    token: token
  })
    .then(() => {})
    .catch((error) => {
      modal.value = true
    })
}

onMounted(() => {
  getToken(route.params?.token)
})
</script>

<style lang="scss" scoped>
.token-auth,
.container-fluid {
  background-color: $primary;
  height: 100vh;
}

.container-fluid {
  img {
    width: 200px;
  }

  span {
    color: $white;
    font-size: 20px;
  }
}
</style>
