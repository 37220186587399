import * as types from './types'

export default {
  [types.STATUS_PROPOSAL_MODAL]: (state) => {
    return state.showModal
  },
  [types.USER]: (state) => {
    return state.user
  },
  [types.TOKEN]: (state) => {
    return state.token
  },
  [types.CUSTOMER]: (state) => {
    return state.customer
  }
}
