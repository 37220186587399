<template>
  <div
    v-if="statusProposalModal"
    class="modal-proposal d-flex align-items-center justify-content-center"
  >
    <section class="d-flex align-items-center justify-content-center flex-column">
      <div class="box-top bg-primary position-relative">
        <img
          class="position-absolute"
          src="@/assets/images/close.svg"
          alt="fechar"
          @click="showProposalModal()"
        />
        <div class="box-top__text text-white text-center">
          <h1 class="font-weight-bold font-rubik mb-2">Contrato gerado!</h1>
          <p>Parabéns! Agora você está a três passos de planejar o seu futuro financeiro:</p>
        </div>
      </div>
      <div class="box-info bg-white p-4">
        <div class="box-info__text">
          <p class="mb-3">3º Assine o contrato para garantir o seu planejamento (Via e-mail);</p>
          <p class="mb-3">4º Realize o investimento;</p>
          <p class="text-secondary font-weight-bold mb-3">5º Agende a sua próxima reunião.</p>
        </div>
        <div class="box-info__button d-flex justify-content-center">
          <a class="text-white bg-secondary p-2" :href="link" rel="noopener noreferrer">
            Vamos planejar o meu Futuro!
          </a>
        </div>
      </div>
      <div class="modal-background" @click="showProposalModal()"></div>
    </section>
  </div>
</template>

<script setup>
import { store } from '@/store'
import { computed } from 'vue'
import * as types from '@/store/types'

const props = defineProps({
  link: String
})

const { [types.SHOW_PROPOSAL_MODAL]: showProposalModal } = store

const statusProposalModal = computed(() => store[types.STATUS_PROPOSAL_MODAL])
</script>

<style lang="scss" scoped>
.modal-background,
.modal-proposal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
}
.modal-background {
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-proposal {
  z-index: 4;

  section {
    width: 332px;
    // height: 382px;
    border-radius: 20px;

    .box-top {
      width: 100%;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      padding: 2.5rem 1.5rem 1.5rem 1.5rem;
      z-index: 1;
      &__text {
        h1 {
          font-size: 24px;
        }

        p {
          font-size: 14px;
        }
      }

      img {
        top: 18px;
        right: 18px;
        cursor: pointer;
      }
    }

    .box-info {
      width: 100%;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      z-index: 1;

      &__text {
        p {
          font-size: 14px;
        }
      }

      &__button {
        width: 100%;

        a {
          font-size: 14px;
          border-radius: 10px;
          text-decoration: none;
        }
      }
    }
  }
}
</style>
