// getters
export const STATUS_PROPOSAL_MODAL = 'src/STATUS_PROPOSAL_MODAL'
export const USER = 'src/USER'
export const TOKEN = 'src/TOKEN'
export const CUSTOMER = 'src/CUSTOMER'

// actions
export const SHOW_PROPOSAL_MODAL = 'src/SHOW_PROPOSAL_MODAL'
export const GET_USER_DATA_CREDENTIALS = 'src/GET_USER_DATA_CREDENTIALS'
export const GET_USER_DATA = 'src/GET_USER_DATA'
export const LOGIN = 'src/LOGIN'
export const LOGOUT = 'src/LOGOUT'
export const SET_TOKEN = 'src/SET_TOKEN'
export const GET_CUSTOMER = 'src/GET_CUSTOMER'
export const UPDATE_PAYMENT = 'src/UPDATE_PAYMENT'
export const GENERATE_CONTRACT = 'src/GENERATE_CONTRACT'
