import { createRouter, createWebHistory } from 'vue-router'
import TokenAuth from '@/views/TokenAuth.vue'
import Unauthorized from '@/views/Unauthorized.vue'
import IndexProposal from '@/views/IndexProposal.vue'
import { store } from '@/store'
import * as types from '@/store/types'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/autenticacao/:token',
      name: 'auth-token',
      component: TokenAuth
    },
    {
      path: '/:token',
      name: 'index',
      component: IndexProposal,
      beforeEnter: (to, from, next) => {
        if (!to.params.token) {
          next({ name: 'unauthorized' })
        } else {
          const user = to.params.token
          store[types.GET_CUSTOMER](user)
          next()
        }
      }
    },
    {
      path: '/nao-autorizado',
      name: 'unauthorized',
      component: Unauthorized
    },
    {
      path: '/',
      redirect: { name: 'unauthorized' }
    }
  ]
})

export default router
