<template>
  <div>
    <section
      class="about-you bg-white d-flex align-items-center justify-content-center flex-column position-relative"
    >
      <div
        class="about-you__container w-100 d-flex align-items-center justify-content-start flex-column position-relative"
      >
        <div class="about-you__container__description mb-5 text-primary text-center">
          <h1 id="payment" class="font-rubik">O seu <b>plano personalizado:</b></h1>
        </div>

        <div
          :class="[
            'about-you__container__flag text-white position-relative d-flex align-items-center justify-content-center',
            !isFinancialManagement ? 'mb-2' : 'mb-3'
          ]"
          data-aos="zoom-in"
        >
          <span>Proposta válida por <b>{{ expirationDays }} dias</b>.</span>
          <img src="@/assets/images/faixa.svg" alt="faixa" />
        </div>

        <div
          :class="[
            'about-you__container__description',
            !isFinancialManagement ? 'mb-5' : 'mb-4',
            'text-primary text-center'
          ]"
        >
          <h1 class="mb-2">
            <b v-if="!isFinancialManagement">{{ maxInstallmentAmount }}</b>
            <b v-else-if="isFinancialManagement">{{ subscriptionValue }}</b>
            mensais
          </h1>
          <p v-if="!isFinancialManagement">
            <b>à partir do segundo ano: {{ subscriptionValue }}</b> + projeto (dependendo da
            necessidade)
          </p>
        </div>

        <div class="about-you__container__description mb-5 text-primary text-center w-75 w-sm-100">
          <p v-if="!isFinancialManagement">
            Contratando agora, além do seu projeto de vida que apresentarei na próxima reunião, você
            terá: <b>{{ followUpInfo }}</b> + Suporte ilimitado pelo WhatsApp + Acesso aos nossos
            cursos de finanças pessoais, workshops ao vivo e clube de descontos exclusivo para
            clientes <b class="text-secondary">Futuro</b> e, em breve, um app exclusivo para você.
          </p>
          <p v-else-if="isFinancialManagement">
            Ao contratar agora, você terá
            <b>{{ followUpInfo }} com nosso planejador</b> para análise de fluxo de caixa, feedbacks
            e orientações estratégicas. Você também terá acesso à ferramenta de fluxo de caixa
            dentro do app Meu Futuro. <br /><br />
            Este suporte é essencial para manter o controle de suas despesas de forma contínua.
            Lembre-se de que o acesso no app Meu Futuro se limita à funcionalidade de fluxo de
            caixa, excluindo outras ferramentas como o clube de benefícios ou a escola de
            negócios.<br /><br />
            Estamos à disposição para discutir mais detalhes e começar a transformar seu
            planejamento financeiro!
          </p>
        </div>

        <div class="about-you__container__box-form d-flex">
          <div class="mb-4 mb-sm-0 d-flex justify-content-center">
            <div
              class="about-you__container__box-form__base-form mb-5 mb-lg-0"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div
                :class="[
                  'about-you__container__box-form__base-form__first-form text-center',
                  !isFinancialManagement
                    ? ''
                    : 'h-100 d-flex align-items-center justify-content-between flex-column'
                ]"
              >
                <div
                  :class="[
                    'about-you__container__box-form__base-form__first-form__top bg-orange text-center mb-3',
                    !isFinancialManagement ? '' : 'w-100'
                  ]"
                >
                  <h3 class="text-white font-weight-bold">
                    {{ isFinancialManagement ? 'GESTÃO FINANCEIRA' : 'MAIS ESCOLHIDO' }}
                  </h3>
                </div>

                <div class="px-4">
                  <h2 class="mb-4 text-black">
                    {{ isFinancialManagement ? 'Valor do investimento' : 'Investimento à vista' }}
                  </h2>

                  <h3 v-if="!isFinancialManagement" class="text-black font-weight-bold">
                    {{ cashPayment }}
                  </h3>
                  <h1 v-else-if="isFinancialManagement" class="font-weight-bold mb-4">
                    {{ subscriptionValue }}
                    <h3 class="font-weight-normal">mensais</h3>
                  </h1>

                  <p v-if="!isFinancialManagement" class="mb-4 text-black">
                    <b>+ {{ subscriptionValue }}</b> mensais
                  </p>

                  <p v-if="!isFinancialManagement" class="text-blue-dark mb-5">
                    <b>{{ cashDiscount }}</b> de desconto total
                  </p>

                  <ul v-else-if="isFinancialManagement" class="mb-3 text-blue-dark">
                    <li>+ <b>Uma</b> reunião por mês</li>
                    <li>+ App Futuro (apenas Gestão Financeira)</li>
                    <li>+ Suporte ilimitado pelo WhatsApp</li>
                  </ul>
                </div>

                <button
                  class="btn-form bg-orange mb-4"
                  id="show-btn"
                  @click="submitProposal('pix')"
                >
                  Próximo passo
                </button>
              </div>
            </div>
          </div>

          <div v-if="!isFinancialManagement" class="d-flex justify-content-center">
            <div
              class="about-you__container__box-form__base-form"
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <div class="about-you__container__box-form__base-form__first-form text-center">
                <div
                  class="about-you__container__box-form__base-form__first-form__top bg-primary text-center mb-3"
                ></div>

                <div class="px-4">
                  <h2 class="mt-5 mb-4 text-black">Investimento parcelado</h2>

                  <Multiselect
                    v-model="selectedParcel"
                    :options="optionsParcel"
                    :canDeselect="false"
                    :canClear="false"
                  >
                    <template v-slot:singlelabel="{ value }">
                      <div class="multiselect-single-label">
                        {{ value.label.installment_quantity }}
                        {{ value.label.installment_quantity > 1 ? 'vezes' : 'vez' }}
                      </div>
                    </template>

                    <template v-slot:option="{ option }">
                      {{ option.label.installment_quantity }}
                      {{ option.label.installment_quantity > 1 ? 'vezes' : 'vez' }}
                    </template>
                  </Multiselect>

                  <p class="text-black mt-3">
                    <b>{{ totalAmount }}</b> por
                    <b
                      >{{ Number(selectedParcel) + 1 }}
                      {{ selectedParcel > 1 ? 'meses' : 'mês' }}</b
                    >
                  </p>
                  <p class="mb-5 text-black">
                    <b>+ {{ subscriptionValue }}</b> mensais
                  </p>
                </div>

                <button class="btn-form bg-orange mb-4" @click="submitProposal('credit')">
                  Próximo passo
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <ModalProposal :link="checkouPayment" />
    <a class="btn-float" href="#payment"><img src="@/assets/images/main-down.svg" alt="drop" /></a>
    <div v-if="loading" class="glass">
      <pixel-spinner :animation-duration="1500" :pixel-size="70" color="#ff7a00" />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import Multiselect from '@vueform/multiselect'
import ModalProposal from '@/components/ModalProposal.vue'
import { store } from '@/store'
import * as types from '@/store/types'
import { storeToRefs } from 'pinia'
import { useRouter, useRoute } from 'vue-router'
import { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import { PixelSpinner } from 'epic-spinners'
import { FINANCIAL_MANAGEMENT } from '@/constants/financial_solution'

const route = useRoute()
const token = route.params?.token

const {
  [types.SHOW_PROPOSAL_MODAL]: showProposalModal,
  [types.UPDATE_PAYMENT]: updatePayment,
  [types.GENERATE_CONTRACT]: generateContract
} = store

const { [types.CUSTOMER]: customer } = storeToRefs(store)

const loading = ref(false)

const selectedParcel = ref(4)

const expirationDays = computed(() => {
  return customer.value?.expiration_days || 7
})

const isFinancialManagement = computed(() => {
  return customer.value?.subscription?.financial_solution_id === FINANCIAL_MANAGEMENT
})

const cashPayment = computed(() => {
  const total = customer.value?.ep?.parcels?.['0']?.total_amount || 0

  return formatAllMoney(total)
})

const cashDiscount = computed(() => {
  const total = customer.value?.ep?.cash_payment_discount || 0

  return formatAllMoney(total)
})

const subscriptionValue = computed(() => {
  const total = customer.value?.subscription?.value || 0

  return formatAllMoney(total)
})

const followUpInfo = computed(() => {
  return customer.value?.subscription?.follow_up_info || ''
})

const totalAmount = computed(() => {
  const total = customer.value?.ep?.parcels?.[selectedParcel.value]?.installment_amount || 0

  return formatAllMoney(total)
})
const maxInstallmentAmount = computed(() => {
  const total =
    customer.value?.ep?.parcels?.[11]?.installment_amount + customer.value?.subscription?.value || 0

  return formatAllMoney(total)
})

const optionsParcel = computed(() => {
  let parcelsArr = customer.value?.ep?.parcels || []

  let object = parcelsArr.reduce((obj, item, index) => {
    obj[index] = item
    return obj
  }, {})

  return object
})

const checkouPayment = computed(() => {
  return `${process.env.VUE_APP_INTERVIEW_URL}/investimento/${token}`
})

function formatAllMoney(value) {
  const options = {
    style: 'currency',
    currency: 'BRL'
  }
  return new Intl.NumberFormat('pt-BR', options).format(value)
}

function submitProposal(method) {
  const payload = {
    installment_quantity: method === 'pix' ? 1 : Number(selectedParcel.value) + 1,
    payment_method: method
  }

  loading.value = true

  updatePayment(token, payload)
    .then(() => {
      generateContract()
        .then((response) => {
          showProposalModal()
        })
        .catch((error) => {
          toast.error(
            'Não foi possível gerar o seu contrato! Entre em contato com o seu consultor',
            {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
              autoClose: 5000,
              toastStyle: {
                fontSize: '14px'
              }
            }
          )
        })
        .finally(() => {
          loading.value = false
        })
    })
    .catch((error) => {
      loading.value = false
      toast.error('Não foi possível gerar o seu contrato! Entre em contato com o seu consultor', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
        autoClose: 5000,
        toastStyle: {
          fontSize: '14px'
        }
      })
    })
    .finally(() => {})
}
</script>

<style lang="scss" scoped>
.about-you {
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow-x: hidden;

  &__container {
    width: 100%;
    height: 100%;
    padding: 125px 16.666%;

    @include media-sm-down {
      padding: 125px 8.333% !important;
    }

    &__description {
      h1 {
        font-size: 48px;
      }

      p {
        font-size: 16px;
      }
    }

    &__flag {
      span {
        font-size: 16px;
        position: absolute;
      }

      img {
        width: 267.89px;
      }
    }

    &__box-form {
      width: 100%;
      justify-content: space-around;
      @include media-xl-down {
        justify-content: space-between;
      }
      @include media-lg-down {
        flex-direction: column;
      }
      &__base-form {
        width: 100%;
        min-width: 378px;
        height: 400px;
        background-color: #fcfcfc;
        border-radius: 20px;
        box-shadow: 0px 4px 15.399999618530273px 1px rgba(0, 0, 0, 0.1);

        &__first-form {
          &__top {
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            padding: 1rem 0;
          }

          p {
            font-size: 20px;
          }

          ul {
            font-size: 14px;
          }

          .btn-form {
            width: 259px;
            padding: 0.5rem 0;
            border-radius: 20px;
            border: none;
            font-size: 16px;
            color: $white;
          }
        }
      }
    }
  }
}

.btn-float {
  position: fixed;
  bottom: 50px;
  right: 25px;
  z-index: 2;

  img {
    width: 50px;
    height: 50px;
  }
}

.glass {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 10;
}
</style>
