<template>
  <div>
    <router-view />
  </div>
</template>

<script setup>
import { initApi } from '@/helpers/api'

initApi()
</script>
