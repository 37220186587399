<template>
  <div>
    <section
      class="about-you d-flex align-items-center justify-content-center flex-column position-relative"
    >
      <div
        class="about-you__container text-white w-100 d-flex align-items-center justify-content-start flex-column position-relative mb-3"
      >
        <div class="about-you__container__description mb-4">
          <p class="text-center mb-3">
            Vamos relembrar a parte mais importante da nossa última reunião,
            <b class="text-secondary">os seus objetivos</b>:
          </p>
        </div>
        <div
          class="about-you__container__until-here"
          data-aos="zoom-in-up"
          data-aos-duration="1000"
        >
          <div
            class="about-you__container__until-here__box d-flex justify-content-start flex-column"
            v-for="(objectives, index) in customer.interview_goals"
            :key="index"
          >
            <div class="about-you__container__until-here__box__image">
              <img
                v-if="objectives.title === 'Compra ou troca do veículo'"
                src="@/assets/images/goals/goal-car.jpeg"
                alt="objetivo"
                loading="lazy"
              />
              <img
                v-if="objectives.title === 'Ajudar filhos nos estudos e moradia'"
                src="@/assets/images/goals/goal-children.jpeg"
                alt="objetivo"
                loading="lazy"
              />

              <img
                v-if="objectives.title === 'Segurança financeira da família'"
                src="@/assets/images/goals/goal-financial.jpeg"
                alt="objetivo"
                loading="lazy"
              />

              <img
                v-if="objectives.title === 'Imóvel próprio ou investimento imobiliário'"
                src="@/assets/images/goals/goal-home.jpeg"
                alt="objetivo"
                loading="lazy"
              />

              <img
                v-if="objectives.title === 'Investimento'"
                src="@/assets/images/goals/goal-investment.jpeg"
                alt="objetivo"
                loading="lazy"
              />

              <img
                v-if="objectives.title === 'Outros objetivos: casamento, intercâmbio, etc.'"
                src="@/assets/images/goals/goal-others.jpeg"
                alt="objetivo"
                loading="lazy"
              />

              <img
                v-if="objectives.title === 'Planejamento organização financeira'"
                src="@/assets/images/goals/goal-planning.jpeg"
                alt="objetivo"
                loading="lazy"
              />

              <img
                v-if="objectives.title === 'Aposentadoria'"
                src="@/assets/images/goals/goal-retirement.jpeg"
                alt="objetivo"
                loading="lazy"
              />

              <img
                v-if="objectives.title === 'Viagens'"
                src="@/assets/images/goals/goal-travel.jpeg"
                alt="objetivo"
                loading="lazy"
              />
            </div>
            <div class="gray-line bg-gray my-3"></div>
            <p class="text-white text-center m-0">{{ objectives.title }}</p>
          </div>
        </div>
        <img
          class="mt-3"
          src="@/assets/images/arrow-down.svg"
          alt="seta"
          data-aos="fade-down"
          data-aos-duration="1500"
        />
      </div>

      <div class="achievements mb-5">
        <div class="achievements__initial-text">
          <p class="text-white mb-3 text-center">
            Quando falamos de <b>planejar o objetivo</b>, não é apenas garantir que vamos
            conquistá-lo, mas, principalmente,
            <b>alcançá-lo da forma mais rápida e econômica possível</b>.
          </p>
          <p class="text-white mb-3 text-center">
            Para exemplificar melhor o resultado de quem se planeja financeiramente, confira o que
            já alcançamos para mais de 20 mil famílias:
          </p>
        </div>
        <div class="achievements__box-gradient d-flex justify-content-center flex-column mx-5">
          <div
            class="achievements__box-gradient__list d-flex justify-content-center flex-column mb-3"
            data-aos="zoom-in-up"
            data-aos-duration="1000"
          >
            <p>
              1. Já são mais de 6 milhões e quatrocentos mil reais economizados em tarifas bancárias
              para os nossos clientes.
            </p>

            <div class="white-line bg-white mt-3"></div>
          </div>

          <div
            class="achievements__box-gradient__list d-flex justify-content-center flex-column mb-3"
            data-aos="zoom-in-up"
            data-aos-duration="1500"
          >
            <p>2. São mais de 10 milhões de reais economizados em aquisições;</p>

            <div class="white-line bg-white mt-3"></div>
          </div>
          <div
            class="achievements__box-gradient__list d-flex justify-content-center flex-column mb-3"
            data-aos="zoom-in-up"
            data-aos-duration="2000"
          >
            <p>3. Mais de 100 milhões de patrimônio dos nossos clientes sob gestão;</p>

            <div class="white-line bg-white mt-3"></div>
          </div>
          <div
            class="achievements__box-gradient__list d-flex justify-content-center flex-column mb-3"
            data-aos="zoom-in-up"
            data-aos-duration="2500"
          >
            <p>
              4. Mais de 50 milhões de reais economizados para os nossos clientes no último ano;
            </p>
          </div>
        </div>
      </div>
      <div
        class="financial-life text-white d-flex justify-content-center align-items-center flex-column"
      >
        <div class="financial-life__description text-center font-weight-bold">
          <p>
            Mas além dos seus objetivos, existem diversas áreas da sua vida financeira que demandam
            muita atenção:
          </p>
        </div>
        <div
          class="financial-life__box-image position-relative d-flex justify-content-center align-items-center flex-column my-5"
        >
          <div class="financial-life__box-image__image">
            <img src="@/assets/images/graph-circle.svg" alt="vida-financeira" />
          </div>
          <span data-aos="fade-right">Planejamento de aposentadoria</span>
          <span data-aos="fade-right">Planejamento tributário</span>
          <span data-aos="fade-right">Planejamento sucessório</span>
          <span data-aos="fade-left">Gestão de risco</span>
          <span data-aos="fade-left">Gestão financeira</span>
          <span data-aos="fade-left">Gestão de ativos</span>
        </div>
        <div
          class="financial-life__box-info position-relative"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <img
            src="@/assets/images/alert.svg"
            alt="alerta"
            data-aos="fade-left"
            data-aos-duration="4000"
          />
          <h3>
            Na nossa próxima reunião, eu vou apresentar, dentro de um plano financeiro,
            <b>todos os pontos de alerta que eu encontrei</b> e como faremos para otimizá-los!
          </h3>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { store } from '@/store'
import * as types from '@/store/types'
import { storeToRefs } from 'pinia'

const { [types.CUSTOMER]: customer } = storeToRefs(store)
</script>

<style lang="scss" scoped>
.about-you {
  width: 100%;
  padding: 125px 16.666%;
  overflow-x: hidden;
  @include media-sm-down {
    padding: 125px 8.333% 0 8.333% !important;
  }

  &__container {
    width: 100%;
    height: 100%;
    // padding: 125px 16.666%;

    &__description {
      p {
        font-size: 36px;

        @include media-sm-down {
          font-size: 24px;
        }
      }
    }

    &__until-here {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
      padding: 1rem;
      border: 1px solid $secondary;
      border-radius: 20px;
      box-shadow: 3px 3px 15px $black;

      @include media-md-down {
        grid-template-columns: 1fr;
      }

      &__box {
        &__image {
          img {
            width: 100%;
            height: 195px;
            border-radius: 20px;
            object-fit: cover;
          }
        }
        .gray-line {
          width: 160px;
          height: 1px;
          align-self: center;
        }

        p {
          font-size: 24px;
        }
      }
    }
  }
}

.achievements {
  &__initial-text {
    p {
      font-size: 24px;

      @include media-sm-down {
        font-size: 16px;
      }
    }
  }
  &__box-gradient {
    border-radius: 20px;
    padding: 3rem;
    background: linear-gradient(0.25turn, #cf5f0d, #ff7a00);
    &__list {
      color: $white;
      text-align: center;

      p {
        font-size: 20px;

        @include media-sm-down {
          font-size: 16px;
        }
      }
      .white-line {
        width: 160px;
        height: 1px;
        align-self: center;
      }
    }
  }
}

.financial-life {
  width: 100%;
  &__description {
    font-size: 24px;
    @include media-sm-down {
      font-size: 16px;
    }
  }

  &__box-image {
    span {
      position: absolute;
      font-size: 24px;

      @include media-md-down {
        position: static;
        text-align: center;
        margin-bottom: 1.5rem;
        font-size: 20px;
        font-weight: bold;
      }
    }

    span:nth-child(2) {
      top: 30px;
      left: -245px;
    }

    span:nth-child(3) {
      top: 210px;
      left: -245px;
    }

    span:nth-child(4) {
      bottom: 25px;
      left: -170px;
    }

    span:nth-child(5) {
      top: 30px;
      right: -85px;
    }

    span:nth-child(6) {
      top: 210px;
      right: -190px;
    }

    span:nth-child(7) {
      bottom: 25px;
      right: -90px;
    }

    &__image {
      height: 442px;
      @include media-sm-down {
        height: auto;
        margin-bottom: 1rem;
      }
    }
  }

  &__box-info {
    max-width: 960px;
    padding: 2rem 10rem 2rem 2rem;
    margin-top: 2.5rem;
    border: 1px solid $white;
    border-radius: 20px;

    @include media-sm-down {
      padding: 2rem 5.5rem 2rem 2rem;
      margin-bottom: 125px;
    }

    h3 {
      @include media-sm-down {
        font-size: 20px;
      }
    }

    img {
      position: absolute;
      top: -50px;
      right: -35px;
    }
  }
}
</style>
